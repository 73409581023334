import React from "react"
import { PageWrapper } from "~components/Core"
import FooterOne from "~sections/marketing/FooterOne"
import BreadCrumbSection from "~sections/portfolios/PortfolioGrid/BreadCrumb";
import AboutSection from '~sections/startup/About'
import PortfolioSection from "~sections/portfolios/PortfolioGrid/PortfolioGridFour"
import ServiceWidget from "~sections/marketing/Service/Component/Widget";
import Service from "~sections/marketing/Service/style";

import { Col, Container, Row } from "react-bootstrap";
import serviceData from "~data/marketing/Service";

const header = {
  headerClasses:
    "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid: true,
  // customLogo: Images.HeaderLogo,
}

export default function BrokerDeSeguros() {
  return (
    <PageWrapper headerConfig={header}>
      <BreadCrumbSection title="BROKER DE SEGUROS" text="Somos un agente especializado y autorizado de seguros."/>
      <Container>
      <Service.Box mtLG="100px" mtMD="40px">
        <Row className="justify-content-center justify-content-md-start">
          {/* Single Service */}
          {serviceData.services.map(({ title, icon,iconColor, text,id }) => {
            return(<Col className="col-lg-2 col-xs-6 col-10" key={id}>
            <ServiceWidget icon={icon} title={title} iconColor={iconColor} id={id} mt="10px" /></Col>) 
          })}
        </Row>
      </Service.Box>
      </Container>
      <AboutSection/>
      <BreadCrumbSection title="Multimarca" text="Nos enorgullece ofrecer cotizaciones de seguros multimarca que garanticen adquieras el indicado."/>
      <PortfolioSection background="#f3f4f6" gutters={true}/>
      <FooterOne />
    </PageWrapper>
  )
}
