import Images from "~data/imageImports";
const serviceData = {
    services: [
        {
          id:"ms1",
          icon: "fas fa-plane",
          iconColor: "#F7F7F7",
          title: "Aeronáutica",
        },
        {
          id:"ms2",
          icon: "fas fa-ship",
          iconColor:"#F7F7F7",
          title: "Náutica",
        },
        {
          id:"ms3",
          icon: "fas fa-car",
          iconColor:"#F7F7F7",
          title: "Automotor",
        },
        {
          id:"ms3",
          icon: "fas fa-building",
          iconColor:"#F7F7F7",
          title: "Inmuebles",
        },
        {
          id:"ms3",
          icon: "fas fa-exclamation-circle",
          iconColor:"#F7F7F7",
          title: "Riesgos",
        },
        {
          id:"ms3",
          icon: "fas fa-first-aid",
          iconColor:"#F7F7F7",
          title: "Salud",
        },
        {
          id:"ms3",
          icon: "fas fa-shield-alt",
          iconColor:"#F7F7F7",
          title: "Ciberseguridad",
        },
        {
          id:"ms3",
          icon: "fas fa-heart",
          iconColor:"#F7F7F7",
          title: "Vida",
        },
        {
          id:"ms3",
          icon: "fas fa-umbrella-beach",
          iconColor:"#F7F7F7",
          title: "Viajes",
        },
        {
          id:"ms3",
          icon: "fas fa-life-ring",
          iconColor:"#F7F7F7",
          title: "Asistencias",
        },
      ]
  }
  export default serviceData;